@font-face {
  font-family: 'GT Sectra Display';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(GT-Sectra-Display/GT-Sectra-Display-Medium.woff2) format('woff2');
}
@font-face {
  font-family: 'GT Sectra Display';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(GT-Sectra-Display/GT-Sectra-Display-Regular-Italic.woff2) format('woff2');
}
@font-face {
  font-family: 'GT Sectra Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(GT-Sectra-Display/GT-Sectra-Display-Regular.woff2) format('woff2');
}
