html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body {
  height: 100%;
  font-size: 14px;
}

#__next {
  height: 100%;
}

// Global progress bar styling
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ff7621;
}

.alliant-partnership-background {
  background-image: url('/images/pattern.svg'),
    linear-gradient(to bottom, #0a373c 600px, transparent 600px 100%);
  background-repeat: no-repeat;
  background-size: 100% 300px, cover;
  background-position: center 300px, top;
}

.alliant-expertise-background {
  background-image: linear-gradient(to bottom, #0a373c 500px, transparent 500px 100%);
}
